.manage-employees td, .manage-employees th,
.schedule td, .schedule th {
  white-space: nowrap;
  padding-left: 3px !important;
  overflow: hidden;
  display: inline-block;
  font-size: 10pt;
}
.schedule td, .schedule th {
  width: 10.625%;
}
@media print {
  .schedule td, .schedule th {
  width: 12.14%;
}
}
.manage-employees td, .manage-employees th {
  width: 33.33%;
}
td.first-column, th.first-column {
  width: 15%;
}
.btn-sm {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
br {
  font-size: 10pt;
}

@media print {
  body {
    zoom: 75%;
  }
  .manage-employees td, .manage-employees th,
  .schedule td, .schedule th {
    color: black !important;
    font-size: 13pt;
  }
  td, th, tr {
    border-color:  black !important;
  }
}